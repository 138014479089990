import augurMsgs from 'common/dist/messages/augurs';
import messages from 'common/dist/messages/error';
import { ColSpec } from 'common/dist/types/dataManagement/cassandra';
import React, { FC } from 'react';
import { FieldValues, RegisterOptions } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import {
  CassandraTableSelectAugurSettings,
  CassandraTableSelectConfig,
} from './type';
import {
  useCassandraTableSamplesForHabitat,
  useCassandraTablesForHabitat,
} from '../../../../../../core/api/data';
import ErrorTile from '../../../../../atoms/error-tile/ErrorTile';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import InputError from '../../../../../atoms/input-error/InputError';
import { HabitatRouteParams } from '../../../../../index/routes';
import CassandraTablePreview from '../../../../../organisms/cassandra-table-preview/CassandraTablePreview';
import { OptionType } from '../../common/type';
import { AugurSettingsProps } from '../../types/meta';

export type Props = CassandraTableDevProps &
  AugurSettingsProps<
    CassandraTableSelectAugurSettings,
    CassandraTableSelectConfig
  >;

export interface CassandraTableDevProps {
  validationOptions?: RegisterOptions<FieldValues, string>;
}

const CassandraTableSelect: FC<Props> = (props) => {
  const {
    config: { keyspace, showTable },
    onChange,
    onBlur,
    isTouched,
    error,
    value,
    disabled,
    portalTarget,
  } = props;
  const { habitatCode } = useParams<HabitatRouteParams>();
  const intl = useIntl();

  const {
    data: tables,
    error: tablesError,
    isError: isTablesError,
    isLoading: isTablesLoading,
  } = useCassandraTablesForHabitat(
    habitatCode ?? undefined,
    keyspace?.dataSourceCode,
    keyspace?.keyspaceName
  );

  const tableOptions: OptionType[] = (tables || []).map((table) => ({
    label: table.name,
    value: table.name,
  }));

  const selectedTable = tables?.find((table) => table.name === value);

  const {
    data: tableSamples,
    error: tableSamplesError,
    isError: isTableSamplesError,
    isLoading: isTableSamplesLoading,
  } = useCassandraTableSamplesForHabitat(
    keyspace?.dataSourceCode,
    keyspace?.keyspaceName,
    selectedTable?.name,
    habitatCode
  );

  if (isTablesError) {
    const errorMessage = tablesError;
    const { values, ...message } = errorMessage.formattedMessage;

    return (
      <ErrorTile
        title={intl.formatMessage(messages.cassandraKeyspace, {
          keyspaceName: keyspace?.keyspaceName,
        })}
        description={intl.formatMessage(message, values)}
      />
    );
  }

  const renderTable = () => {
    if (isTableSamplesError) {
      const errorMessage = tableSamplesError;
      const { values, ...message } = errorMessage.formattedMessage;

      return (
        <ErrorTile
          title={intl.formatMessage(messages.cassandraTablePreview, {
            tableName: value,
          })}
          description={intl.formatMessage(message, values)}
        />
      );
    } else if (isTableSamplesLoading) {
      return;
    } else {
      return (
        <div className={styles.tablePreview}>
          <CassandraTablePreview
            colSpecs={tableSamples?.colSpecs || []}
            data={tableSamples?.data || []}
            editable={false}
            sortBy={(colSpecA: ColSpec, colSpecB: ColSpec) =>
              colSpecA.colName.toLowerCase() > colSpecB.colName.toLowerCase()
                ? 1
                : -1
            }
          />
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div>{error && <InputError touched={isTouched} error={error} />}</div>
        <DropdownSelectInput
          disabled={!tables || disabled}
          label={{
            id: 'no-id',
            defaultMessage: 'Cassandra Keyspace Table',
          }}
          placeholder={augurMsgs.msgTrainingTablePlaceholderTable}
          options={tableOptions}
          autoSelectIfSingle
          onChange={(e: OptionType) => onChange(e.value)}
          onBlur={onBlur}
          value={tableOptions.find((option) => option.value === value) || ''}
          aria-invalid={error ? 'true' : 'false'}
          menuPortalTarget={portalTarget}
          isLoading={isTablesLoading}
        />
      </div>
      {showTable && renderTable()}
    </div>
  );
};

export default CassandraTableSelect;
